<template>
  <div></div>
</template>

<script>
import {mapActions} from "vuex";
export default {
  name: 'Logout',
  async mounted() {
    await this.logout()
    await this.$router.push({name: 'login'})
  },
  methods: {
    ...mapActions('login', [
      'logout',
    ]),
  }
}
</script>
